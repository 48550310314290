<script>
import AdBanner from '@/components/Ads/AdBanner.vue';

export default {
  name: "CatsWrapper",
  components: {AdBanner},
  methods : {
    viewCat(id,title){
      this.$router.push('/categories/' + id + '?title=' + title);
    }
  }
}
</script>

<template>
  <div class="grid-container">
    <div class="grid-item digital-services enabled" @click="viewCat('66afb5c03639e3434ba0ca2d',$t('categories.digital'))"><img :src="`${$cdn}main/digital.png`" alt="">{{$t('categories.digital')}}</div>
    <div class="grid-item business-startups enabled"  @click="viewCat('66afb5d63639e3434ba0ca30',$t('categories.business'))"><img :src="`${$cdn}main/business.png`" alt="">{{$t('categories.business')}}</div>
    <div class="grid-item work enabled"  @click="$router.push('/freelance')"><img :src="`${$cdn}main/jobs.png`" alt="">{{$t('categories.jobs')}}</div>
    <!-- <div class="grid-item work enabled" v-else @click="viewCat('66afb5ad3639e3434ba0ca27',$t('categories.jobs'))"><img :src="`${$cdn}main/jobs.png`" alt="">{{$t('categories.jobs')}}</div> -->
    <div class="grid-item art-handmade enabled" @click="viewCat('66afb5e43639e3434ba0ca33',$t('categories.art'))"><img :src="`${$cdn}main/art.png`" alt="">{{$t('categories.art')}}</div>
    <div class="grid-item real-estate enabled" @click="viewCat('66afb5a33639e3434ba0ca24',$t('categories.building'))"><img :src="`${$cdn}main/buildings.png`" alt="">{{$t('categories.building')}}</div>
    <div class="grid-item market enabled" @click="viewCat('66afb5b53639e3434ba0ca2a',$t('categories.market'))"><img :src="`${$cdn}main/market.png`" alt="">{{$t('categories.market')}}</div>
    <div class="grid-item bulletin-board enabled" @click="viewCat('66afb58b3639e3434ba0ca21',$t('categories.desk'))"><img :src="`${$cdn}main/desk.png`" alt="">{{$t('categories.desk')}}</div>
    <div class="alert">
      
    </div>
  </div>
  <AdBanner/>
  <div class="category">
    <div class="container">
      <span>Digital-услуги</span>
    </div>
    <div class="small-grid-container">
      <div class="grid-item left-1 enabled" @click="viewCat('66b39bc18b1ccad45e5dff39',$t('categories.digital_design'))"><img :src="`${$cdn}main/digital_design.png`" alt="">{{$t('categories.digital_design')}}</div>
      <div class="grid-item left-2 enabled" @click="viewCat('66b39bdc8b1ccad45e5dff3c',$t('categories.digital_code'))"><img :src="`${$cdn}main/digital_code.png`" alt="">{{$t('categories.digital_code')}}</div>
      <div class="grid-item right-up enabled" @click="viewCat('66cb77bb264131f81281781c',$t('categories.digital_marketing'))"><img :src="`${$cdn}main/digital_marketing.png`" alt="">{{$t('categories.digital_marketing')}}</div>
    </div>
  </div>
  
  <div class="category">
    <div class="container">
      <span>Бизнес и стартапы</span>
    </div>
    <div class="small-grid-container">
      <div class="grid-item right-1 enabled" @click="viewCat('66d0b49f264131f8128179bd',$t('categories.start_starts'))"><img :src="`${$cdn}main/start_starts.png`" alt="">{{$t('categories.start_starts')}}</div>
      <div class="grid-item right-2 enabled" @click="viewCat('66d0b4c2264131f8128179c6',$t('categories.start_events'))"><img :src="`${$cdn}main/start_events.png`" alt="">{{$t('categories.start_events')}}</div>
      <div class="grid-item left-up enabled" @click="viewCat('66d0b4b7264131f8128179c3',$t('categories.start_search'))"><img :src="`${$cdn}main/start_search.png`" alt="">{{$t('categories.start_search')}}</div>
    </div>
  </div>
  <div class="category" v-if="this.$store.state.D_MODE != 'on'">
    <div class="container">
      <span>Работа</span>
    </div>
    <div class="small-grid-container">
      <div class="grid-item left-1 enabled" @click="viewCat('66d0b375264131f812817999',$t('categories.jobs_country'))"><img :src="`${$cdn}main/jobs_country.png`" alt="">{{$t('categories.jobs_country')}}</div>
      <div class="grid-item left-2 enabled" @click="viewCat('66d0b363264131f812817996',$t('categories.jobs_management'))"><img :src="`${$cdn}main/jobs_management.png`" alt="">{{$t('categories.jobs_management')}}</div>
      <div class="grid-item right-up enabled" @click="viewCat('66d0b348264131f812817993',$t('categories.jobs_vacancy'))"><img :src="`${$cdn}main/jobs_vacancy.png`" alt="">{{$t('categories.jobs_vacancy')}}</div>
    </div>
  </div>
  <div class="category">
    <div class="container">
      <span>Арт и ручная работа</span>
    </div>
    <div class="small-grid-container">
      <div class="grid-item right-1 enabled" @click="viewCat('66d0b4de264131f8128179c9',$t('categories.art_graphics'))"><img :src="`${$cdn}main/art_graphics.png`" alt="">{{$t('categories.art_graphics')}}</div>
      <div class="grid-item right-2 enabled" @click="viewCat('66d0b4ec264131f8128179cc',$t('categories.art_accessories'))"><img :src="`${$cdn}main/art_accessories.png`" alt="">{{$t('categories.art_accessories')}}</div>
      <div class="grid-item left-up enabled" @click="viewCat('66d0b4f9264131f8128179cf',$t('categories.art_decor'))"><img :src="`${$cdn}main/art_decor.png`" alt="">{{$t('categories.art_decor')}}</div>
    </div>
  </div>
  <div class="category">
    <div class="container">
      <span>Недвижимость</span>
    </div>
    <div class="small-grid-container">
      <div class="grid-item left-1 enabled" @click="viewCat('66d0b254264131f81281796f',$t('categories.buildings_out'))"><img :src="`${$cdn}main/buildings_out.png`" alt="">{{$t('categories.buildings_out')}}</div>
      <div class="grid-item left-2 enabled" @click="viewCat('66cd8c68264131f8128178f8',$t('categories.buildings_new'))"><img :src="`${$cdn}main/buildings_new.png`" alt="">{{$t('categories.buildings_new')}}</div>
      <div class="grid-item right-up enabled" @click="viewCat('66d0b2b4264131f81281797e',$t('categories.buildings_elite'))"><img :src="`${$cdn}main/buildings_elite.png`" alt="">{{$t('categories.buildings_elite')}}</div>
    </div>
  </div>
  <div class="category">
    <div class="container">
      <span>Доска объявлений</span>
    </div>
    <div class="small-grid-container">
      <div class="grid-item right-1 enabled" @click="viewCat('66b3ab2c8b1ccad45e5dff43',$t('categories.desk_electronic'))"><img :src="`${$cdn}main/desk_electronic.png`" alt="">{{$t('categories.desk_electronic')}}</div>
      <div class="grid-item right-2 enabled" @click="viewCat('66b3ab3b8b1ccad45e5dff46',$t('categories.desk_private'))"><img :src="`${$cdn}main/desk_private.png`" alt="">{{$t('categories.desk_private')}}</div>
      <div class="grid-item left-up enabled" @click="viewCat('66b3ab0c8b1ccad45e5dff40',$t('categories.desk_cars'))"><img :src="`${$cdn}main/desk_cars.png`" alt="">{{$t('categories.desk_cars')}}</div>
    </div>
  </div>
  <AdBanner/>
  <div class="category">
    <div class="container">
      <span>Маркет</span>
    </div>
    <div class="small-grid-container">
      <div class="grid-item left-1 enabled" @click="viewCat('66d0b3fd264131f8128179ab',$t('categories.market_clothes'))"><img :src="`${$cdn}main/market_clothes.png`" alt="">{{$t('categories.market_clothes')}}</div>
      <div class="grid-item work enabled" @click="viewCat('66d0b456264131f8128179b1',$t('categories.market_health'))"><img :src="`${$cdn}main/market_health.png`" alt="">{{$t('categories.market_health')}}</div>
      <div class="grid-item right-1 enabled" @click="viewCat('66d0b474264131f8128179b7',$t('categories.market_electronic'))"><img :src="`${$cdn}main/market_electronic.png`" alt="">{{$t('categories.market_electronic')}}</div>
      <div class="grid-item art-handmade enabled" @click="viewCat('66d0b44a264131f8128179ae',$t('categories.market_accessories'))"><img :src="`${$cdn}main/market_accessories.png`" alt="">{{$t('categories.market_accessories')}}</div>
    </div>
  </div>
  
</template>

<style scoped>
.category{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.category span{
  font-weight: 500;
  font-size: 20px;
  background: linear-gradient(90deg, #02AEBA 0%, #0181A8 50%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.left-1{
  grid-column: 1;
  grid-row: 1;
}
.left-2{
  grid-column: 1;
  grid-row: 2;
}
.grid-container, .category{
  background-color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  border-radius: 40px
}

.right-1{
  grid-column: 2;
  grid-row: 1;
}
.right-2{
  grid-column: 2;
  grid-row: 2;
}
.left-up{
  grid-column: 1;
  grid-row: 1;
}
.right-up{
  grid-column: 2;
  grid-row: 1;
}
.alert{
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  backdrop-filter: blur(4px);

}
.alert span{
  font-weight: bold;
}
.grid-item{
  font-size: 13px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.grid-container{
  display: grid;
  padding-top: 15px;
  position: relative;
  height: max-content;

  grid-template-columns: repeat(2, 1fr);

  grid-template-rows: repeat(4, 144px);
}
.small-grid-container{
  display: grid;
  padding-top: 5px;
  position: relative;
  height: max-content;

  grid-template-columns: repeat(2, 1fr);

  grid-template-rows: repeat(2, 144px);
}
.digital-services{
  grid-column: 1;
  grid-row: 1;
}
.business-startups{
  grid-column: 2;
  grid-row: 1;
}
.work{
  grid-column: 1;
  grid-row: 2;
}
.art-handmade{
  grid-column: 2;
  grid-row: 2;
}
.real-estate{
  grid-column: 1;
  grid-row: 3;
}
.market{
  grid-column: 1;
  grid-row: 4;
}
.enabled{
  position: relative;
  z-index: 5;
}
.bulletin-board{
  grid-column: 2;
  grid-row: 3;

}
img{
  border-radius: 20px;
}

</style>