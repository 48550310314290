<script>
import Button from "@/components/Button.vue";
import NavigationComponent from "@/components/Navigation.vue";
import {initUtils} from "@telegram-apps/sdk";
import {initCloudStorage} from "@telegram-apps/sdk";
export default {
  name: "ProfilePage",
  components: {NavigationComponent,Button},
  data(){
    return {
      avatarLoaded : false,
      avatar : "",
      username : null,
      cloud: initCloudStorage(),
      unset: null,
      geo : {
        inited : false,
        granted : false,
        available : false,
        city : undefined,
      },
      clicks : 0,
    }
  },
  methods : {
    waitAvatar(){
      if(this.$refs.avatar && this.$refs.avatar.complete){
        this.avatarLoaded=true;
      }
    },
    support(){
      let utils = initUtils();
      utils.openTelegramLink("https://t.me/Tagito_support")
    },
    openChannel(){
      let utils = initUtils();
      utils.openTelegramLink("https://t.me/+YxtYX7eHY89iZTNi")
    },
    addtohome(){
      this.$gram.WebApp.addToHomeScreen();
    },
    async tapToDev(){
      this.clicks+=1;
      clearTimeout(this.unset)
      this.unset = setTimeout(() => {
        this.clicks = 0;
      }, 1000);
      if(this.clicks == 10){
        this.clicks = 0;
        if(await this.cloud.get('dev_mode') == "on"){
          this.cloud.set('dev_mode',"off")
          alert("Эксперементальный режим выключен")
        }
        else{
          this.cloud.set('dev_mode',"on")
          alert("Эксперементальный режим включен")
        }
        
      }
    },
    fullscreen(){
      document.body.classList.add('ios-screen');
      window.Telegram.WebApp.requestFullscreen();
    },
    openAbout(){
      let utils = initUtils();
      
      if(this.$i18n.locale == "ru"){
        utils.openLink("https://telegra.ph/Instrukciya-k-ispolzovaniyu-TMA-Tagito-09-18",{
          tryInstantView : true
      })
      }
      else{
        utils.openLink("https://telegra.ph/Instructions-for-using-TMA-Tagito-09-30",{
          tryInstantView : true
        })
      }
    },
    getGeo(){
      if(!this.geo.inited && !this.geo.granted || !this.geo.available) return;
      this.$gram.WebApp.LocationManager.getLocation((data)=>{
          if(data != null){
            console.log(data);
            var url = "http://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address";
var token = "8dea5c3834944dc44d2067dd55ab39a1762992ac";
var query = { lat: 55.878, lon: 37.653, count: 1 };

      var options = {
          method: "POST",
          mode: "cors",
          headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Token " + token
          },
          body: JSON.stringify(query)
      }

      fetch(url, options)
      .then(response => response.json())
      .then(result => {
        this.geo.city = result.suggestions[0].data.city;
      })
      .catch(error => console.log("error", error));
          }
      });
    },
    getAccessGeo(){
      if(this.geo.inited && !this.geo.granted && this.geo.available){
        this.getGeo();
      }
    }

  }, 

  mounted() {
    this.avatar = window.Telegram.WebApp.initDataUnsafe.user.photo_url;
    // this.$gram.WebApp.LocationManager.init(()=>{
        
    //     if(this.$gram.WebApp.LocationManager.isInited){
    //       this.geo.inited = true;
    //       this.geo.granted = this.$gram.WebApp.LocationManager.isAccessGranted;
    //       this.geo.available = this.$gram.WebApp.LocationManager.isLocationAvailable;
    //       if(this.geo.granted && this.geo.available){
    //         this.getGeo();
    //       }
    //     }
    //     else{
    //       this.geo.inited = false;
    //     }
    // })

    this.inited = this.Telegram.WebApp.LocationManager.isInited;
   
    
  }
}
</script>

<template>
  <div class="page">

    <div class="fake-avatar" @click="tapToDev()">{{$store.state.userInfo.first_name[0]}}</div>
    <img  v-show="avatarLoaded" @load="waitAvatar" @click="tapToDev()" ref="avatar" :src="avatar" class="avatar" alt="avatar" width="125" height="125">
    <span class="name">{{$store.state.userInfo.first_name}} {{$store.state.userInfo.last_name}}</span>
    <div class="container">
      <div class="menu w-full flex flex-col gap-2">
        <div :class="'menu-item w-full'" @click="openChannel()">
          <div :class="'menu-item-icon'"><img src="@/assets/icons/telegram.svg" alt=""></div>
          <span>{{$t("profile.telegram")}}</span>
        </div>
        <div :class="'menu-item w-full'" @click="support()">
          <div :class="'menu-item-icon'"><img src="@/assets/icons/support2.svg" alt=""></div>
          <span>{{$t("profile.support")}}</span>
        </div>
        <div :class="'menu-item w-full'" @click="$router.push('/settings/locale')">
          <div :class="'menu-item-icon'"><img src="@/assets/icons/language.svg" alt=""></div>
          <span>{{$t("profile.locale")}}</span>
        </div>
        <div :class="'menu-item w-full'" @click="openAbout()">
          <div :class="'menu-item-icon'"><img src="@/assets/icons/about.svg" alt=""></div>
          <span>{{$t("profile.about")}}</span>
        </div>
        <Button @click="addtohome()" v-if="$versionAPI >= 8">Добавить на экран домой</Button>
        <Button @click="addemoji()" v-if="$versionAPI >= 8 && $store.state.D_MODE == 'on'">GEO1</Button>
        <Button @click="fullscreen()" v-if="$versionAPI >= 8 && $store.state.D_MODE == 'on'">FULLSCREEN</Button>
       
      </div>

      <p v-if="geo.inited && geo.available" class="city">Ваш город: <span class="non-find" v-if="!geo.granted" @click="getAccessGeo()">Предоставить доступ</span><span v-if="geo.city">{{ geo.city }}</span></p>
    </div>

  </div>
  <NavigationComponent></NavigationComponent>
</template>

<style scoped>
  .non-find{
    color: #24b9c5;
  }
  .city{
    font-size: 15px;
    width: 100%;
    text-align: center;
  }
  .name{
    font-weight: 500;
    font-size: 18px;
    color: #2D2D2D;
  }
  .page{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    gap: 10px;
  }
  .fake-avatar{
    height: 125px;
    width: 125px;
    border-radius: 50%;
    position: relative;
    background: #24b9c5;
    color: #fff;
    font-size: calc(5vh);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .avatar{
    border-radius: 50%;
    position: absolute;
  }

  .menu-item-icon{
    height: 35px;
    width: 35px;
    
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu-item{
    background: #fff;
    display: flex;
    box-shadow: 0px 0px 10px 0px #0000000D;
    font-size: 15px;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 15px;
  }
</style>