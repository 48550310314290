<script>
import PageBadge from "@/components/PageBadge.vue";
import NavigationComponent from "@/components/Navigation.vue";
import Offer from "@/components/Freelance/Offer.vue";

export default {
  name: "ViewCategory",
  data(){
    return {
      loadingProds : true,
      products : [],
      offers : [],
    }
  },
  async mounted() {
      let offers = await this.$api.get('freelance/get')
      this.offers = offers;

  },
  components: {NavigationComponent, PageBadge, Offer}
}
</script>

<template>
  <div class="page">
    <PageBadge :text="'Портал запросов'" back="1"></PageBadge>
    
    <div class="flex flex-col gap-4 container">
      <Offer v-for="offer of offers" :text="offer.text" :id="offer._id" :contact="offer.contact" v-bind:key="offer._id"></Offer>
    </div>
    <div class="flex flex-col gap-4 container" v-if="offers.length == 0">
        <div class="fake-offer pulse" v-for="i in 10" :key="i">
          <div class="skeleton"></div>
          <div class="skeleton"></div>
          <div class="skeleton-btn"></div>
        </div>
    </div>
    
  </div>

  <NavigationComponent></NavigationComponent>
</template>

<style scoped>
.skeleton{
  width: 100%;
  height: 10px;
  background-color: #ccc;
  border-radius: 10px;
  
}
.fake-offer{
  display: flex;
  border-bottom: 1px solid #ccc;;
  padding-bottom: 20px;
  gap: 10px;
  flex-direction: column;
}
.skeleton-btn{
  width: 100%;
  height: 30px;
  background-color: #ccc;
  border-radius: 20px;
}
</style>